import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _countBy from 'lodash/countBy';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';
import _startCase from 'lodash/startCase';
import _uniq from 'lodash/uniq';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { viaMuiTheme } from '../../../../../../../shared/helpers/muiTableNewTheme';
import { FormGroup, FormLabel, TextField } from '@material-ui/core';
import userId from '../../../../../../../shared/helpers/userId';
import Headline from '../../../../../../components/library/headline';
import Paragraph from '../../../../../../components/library/paragraph';
import AccessDenied from '../../../../../../../sites/viaGlobal/components/pages/accessDenied';
import Button from '../../../../../../components/library/button';
import BatchAddTravelerToPlan from '../../../../modals/addTravelerToPlan/batchAddTravelerToPlan';
import BatchSendTravelersMessage from '../../../../../../../sites/viaGlobal/components/modals/batchSendTravelersMessage';
import ViaModal from '../../../../../../components/library/viaModal';
import TravelerActionsSelect from '../../../../selects/travelerActions';
import AdminDetailToTraveler from '../../../../modals/adminDetailToTraveler';
import AdminDetailFromTraveler from '../../../../modals/adminDetailFromTraveler';
import MakeGroupLead from '../../../../modals/makeGroupLead';
import RemoveGroupLead from '../../../../modals/removeGroupLead';
import RemoveTraveler from '../../../../modals/removeTraveler';
import ReinstateTraveler from '../../../../modals/reinstateTraveler';
import SendSafecheck from '../../../../modals/sendSafecheck';
import ViaIcon from '../../../../ViaIcon';
import CityScape from '../../../../../../../../public/img/cityscape-cropped.png';
import { emptyStringOrValue } from '../../../../../../../shared/helpers/General';
import ToastMessage from '../../../../../../viaGlobal/components/toastMessage';
import { debounceSearchRender, DEBOUNCE_VALUE } from '../../../../../../../shared/helpers/DebounceSearchRender';
import sOvTravelers from './ovTravelers.scss';
import AddPlanFormsToTravelersModal from '../../../../../../../components/AddPlanFormsToTravelersModal';
import BatchDownloadPdfModal from '../../../../../../../components/BatchDownloadPdfModal';
import { getPlan } from '../../../../../../../actions/plans';
export const enhance = compose(withStyles(sOvTravelers), withRouter);

function OvTravelers(props) {
  const FILTER_TYPE_NAME = 'ov_vt_travelers';
  const dispatch = useDispatch();
  const tableState = JSON.parse(localStorage.getItem(FILTER_TYPE_NAME));
  const [tableData, setTableData] = useState([]);
  const customAliases = useSelector(state => state.profile.customAliases);
  const selectedPlan = useSelector(state => state.plans.plan);
  const [viewStyle, setViewStyle] = useState(props.as === 'admin' ? 'table' : 'roster');
  const [showAddTraveler, setShowAddTraveler] = useState(false);
  const addTravelers = useSelector(state => state.plans.addTravelers);
  const [selectedTravelers, setSelectedTravelers] = useState([]);
  const [showAddDetails, setShowAddDetails] = useState(false);
  const [showRemoveDetails, setShowRemoveDetails] = useState(false);
  const [showMakeGroupLead, setShowMakeGroupLead] = useState(false);
  const [showRemoveGroupLead, setShowRemoveGroupLead] = useState(false);
  const [showRemoveTraveler, setShowRemoveTraveler] = useState(false);
  const [showReinstateTraveler, setShowReinstateTraveler] = useState(false);
  const [showSendSafecheck, setShowSendSafecheck] = useState(false);
  const [showSendEmailToTravelers, setShowSendEmailToTravelers] = useState(false);
  const [showAddPlanForms, setShowAddPlanForms] = useState(false);
  const [showBatchDownloadPdfModal, setShowBatchDownloadPdfModal] = useState(false);
  const [planFormsUsers, setPlanFormsUsers] = useState([]);
  const permissions = useSelector(state => state.profile.permissions);
  const currentUser = useSelector(state => state.profile.currentUser);
  const [planUserIds, setPlanUserIds] = useState();
  const [travelerIds, setTravelerIds] = useState();
  const [showToaster, setShowToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('');
  const [selectDefault, setSelectDefault] = useState();
  const [goingList, setGoingList] = useState();
  const [tableSort, setTableSort] = useState({ name: 'name', direction: 'asc' });
  const [cols, setCols] = useState();
  const [savedFilters, setSavedFilters] = useState({});
  const [travelerCounts, setTravelerCounts] = useState({
    travelers: 0,
    leaders: 0,
    total: 0,
  });
  const [travelerTableCounts, setTravelerTableCounts] = useState({
    travelers: 0,
    leaders: 0,
    total: 0,
  });
  const [transportationFilterChecked, setTransportationFilterChecked] = useState(false);
  const [housingFilterChecked, setHousingFilterChecked] = useState(false);
  const [activityFilterChecked, setActivityFilterChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMsg, setLoadingMsg] = useState('Loading travelers...');
  const [filterOptions, setFilterOptions] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const selectOptions = [
    // { label: 'SMS TEXT', value: '1', isDisabled: true },
    { label: 'Send Email', value: '2' },
    { label: 'Send SafeCheck', value: '3' },
    { label: 'Add Details To Travelers', value: '4' },
    { label: 'Remove Details From Travelers', value: '5' },
    { label: 'Remove Travelers', value: '6' },
    { label: 'Reinstate Travelers', value: '7' },
    {
      label: 'Make Group Leader',
      value: '8',
      isDisabled: props.as !== 'admin',
    },
    { label: 'Remove Group Leader', value: '9' },
    { label: 'Add Plan Forms to Traveler(s)', value: '10' },
    { label: 'Download PDF', value: '11' },
  ];

  if (props.as !== 'admin') {
    selectOptions.shift();
  }
  useEffect(() => {
    if (!!addTravelers) {
      if (!addTravelers.loading && !!addTravelers.travelersAdded && !!addTravelers.travelersAdded.message) {
        setShowToaster(true);
        setToasterMessage('Unable to add traveler to plan');
      }
      if (
        !!addTravelers.travelersAdded &&
        !!addTravelers.travelersAdded.data &&
        addTravelers.travelersAdded.data.length === 0
      ) {
        setShowToaster(true);
        setToasterMessage('Unable to add traveler(s) to plan');
      }
    }
  }, [addTravelers]);

  useEffect(() => {
    if (!!selectedPlan && !!selectedPlan.travelers && !selectedPlan.loading) {
      setLoading(false);
      if (!selectedPlan.travelers || selectedPlan.travelers.length < 1) {
        setLoadingMsg('No travelers found');
      }

      let formatData = _map(selectedPlan.travelers, traveler => {
        let locs = '';
        if (selectedPlan.plan.attributes.locations && selectedPlan.plan.attributes.locations.length > 0) {
          selectedPlan.plan.attributes.locations.map(loc => {
            locs = locs + loc.locality + ', ' + loc.country_common_name + '; ';
          });
          locs = locs.slice(0, -2);
        }

        let travelerName = "";
        if (traveler.first_name || traveler.last_name) {
          travelerName = `${traveler.first_name || ''} ${traveler.last_name || ''}`.trim();
        } else if (traveler.preferred_first_name || traveler.preferred_last_name) {
          travelerName = `${traveler.preferred_first_name || ''} ${traveler.preferred_last_name || ''}`.trim();
        }

        const buildData = {
          plan_user_id: traveler.plans_user_id,
          role: traveler.group_lead ? 'Group Leader' : 'Traveler',
          name: travelerName,
          sortName:
            (traveler.first_name && traveler.first_name.trim().length > 0) ||
            (traveler.last_name && traveler.last_name.trim().length > 0)
              ? `${traveler?.first_name?.toLowerCase()} ${traveler?.last_name?.toLowerCase()}`
              : traveler?.email?.toLowerCase(),
          email: traveler?.email,
          phone: traveler.phone_number ? `${traveler.phone_number}` : '',
          transportation: traveler.plan_transportations_count,
          activity: traveler.plan_activities_count,
          housing: traveler.plan_housings_count,
          status:
            (!selectedPlan.plan.attributes.group && selectedPlan.plan.attributes.removed) ||
            (selectedPlan.plan.attributes.group && traveler.removed)
              ? 'Not Going'
              : 'Going',
          plan_name: selectedPlan.plan.attributes.name,
          plan_type_name: selectedPlan.plan.attributes.plan_type_name,
         // plan_start: selectedPlan.plan.attributes.start_date,
          plan_start: moment(selectedPlan.plan.attributes.start_date).isValid() ? moment(selectedPlan.plan.attributes.start_date).format('MMM D, YYYY') : 'Not Provided',
          //plan_end: selectedPlan.plan.attributes.end_date,
          plan_end: moment(selectedPlan.plan.attributes.end_date).isValid() ? moment(selectedPlan.plan.attributes.end_date).format('MMM D, YYYY') : 'Not Provided',
          locations: locs,
          avatar: traveler.avatar_url,
          traveler_user_id: traveler.id,
          safe_check_phone_number: traveler.safe_check_phone_number ? `${traveler.safe_check_phone_number}` : '',
          safe_check_enrollment_status: _startCase(
            traveler.safe_check_enrollment_status.replace(/_/g, ' ').toLowerCase(),
          ),
          student_id: emptyStringOrValue(traveler.student_id),
          preferred_first_name: emptyStringOrValue(traveler.preferred_first_name),
          traveler_type: emptyStringOrValue(traveler.traveler_type),
          date_of_birth: emptyStringOrValue(traveler.date_of_birth),
          emergency_contact_name: emptyStringOrValue(traveler.emergency_contact_name),
          emergency_contact_relationship: emptyStringOrValue(traveler.emergency_contact_relationship),
          emergency_contact_phone_number: emptyStringOrValue(traveler.emergency_contact_phone_number),
          emergency_contact_email: emptyStringOrValue(traveler.emergency_contact_email),
          emergency_contact_address: emptyStringOrValue(traveler.emergency_contact_address),
          secondary_emergency_contact_name: emptyStringOrValue(traveler.secondary_emergency_contact_name),
          secondary_emergency_contact_relationship: emptyStringOrValue(
            traveler.secondary_emergency_contact_relationship,
          ),
          secondary_emergency_contact_phone_number: emptyStringOrValue(
            traveler.secondary_emergency_contact_phone_number,
          ),
          secondary_emergency_contact_email: emptyStringOrValue(traveler.secondary_emergency_contact_email),
          secondary_emergency_contact_address: emptyStringOrValue(traveler.secondary_emergency_contact_address),
          countries_of_citizenship: emptyStringOrValue(traveler.countries_of_citizenship),
          passport_number: emptyStringOrValue(traveler.passport_number),
          passport_issuer: emptyStringOrValue(traveler.passport_issuer),
          passport_status: emptyStringOrValue(traveler.passport_status),
          place_of_birth: emptyStringOrValue(traveler.place_of_birth),
          residency_status: emptyStringOrValue(traveler.residency_status),
          visa_status: emptyStringOrValue(traveler.visa_status),
          sex: emptyStringOrValue(traveler.sex),
          gender_identity: emptyStringOrValue(traveler.gender_identity),
          pronoun: emptyStringOrValue(traveler.pronoun),
          race: emptyStringOrValue(traveler.race),
          ethnicity: emptyStringOrValue(traveler.ethnicity),
          disability: emptyStringOrValue(traveler.disability),
          custom_fields: emptyStringOrValue(traveler.custom_fields),

          number_of_forms: traveler.number_of_forms ? emptyStringOrValue(traveler.number_of_forms) : 0,
          plan_forms: traveler.plan_forms ? emptyStringOrValue(traveler.plan_forms) : "Not Provided",
          number_of_accepted_forms: traveler.number_of_accepted_forms ? emptyStringOrValue(traveler.number_of_accepted_forms) : 0,
          accepted_forms: traveler.accepted_forms ? emptyStringOrValue(traveler.accepted_forms) : "Not Provided",
          number_of_submitted_forms: traveler.number_of_submitted_forms ? emptyStringOrValue(traveler.number_of_submitted_forms) : 0,
          submitted_forms: traveler.submitted_forms ? emptyStringOrValue(traveler.submitted_forms) : "Not Provided",
          number_of_started_forms: traveler.number_of_started_forms ? emptyStringOrValue(traveler.number_of_started_forms) : 0,
          started_forms: traveler.started_forms ? emptyStringOrValue(traveler.started_forms) : "Not Provided",
          number_of_not_started_forms: traveler.number_of_not_started_forms ? emptyStringOrValue(traveler.number_of_not_started_forms) : 0,
          not_started_forms: traveler.not_started_forms ? emptyStringOrValue(traveler.not_started_forms) : "Not Provided",
        };
        return buildData;
      });

      const savedFilters = _get(tableState, 'buildSaveFilter') || tableState;
      const savedSort = _get(tableState, 'sortOption') || tableSort;

      formatData = _orderBy(formatData, ['name'], ['asc']);
      let travelersGoing = formatData.filter(traveler => traveler.status?.toLowerCase() === 'going');
      travelersGoing = _sortBy(travelersGoing, ['role', 'sortName'], ['asc', 'asc']);
      const goingCounts = _countBy(travelersGoing, 'role');

      setTravelerCounts({
        travelers: goingCounts['Traveler'] || 0,
        leaders: goingCounts['Group Leader'] || 0,
        total: (goingCounts['Traveler'] || 0) + (goingCounts['Group Leader'] || 0),
      });

      setGoingList(travelersGoing);
      updateTravelerTableCounts(travelersGoing);
      setSelectedTravelers([]); //reset selected to travelers
      applyFilterToTableData(savedFilters, savedSort);
      setTableData(formatData); // reset table data
    }
  }, [selectedPlan]);
  const applyFilterToTableData = (filter, sort = tableSort) => {
    localStorage.setItem(FILTER_TYPE_NAME, JSON.stringify(filter));

    const filteredCols = cols || columns;
    const buildSaveFilter = [];

    if (filteredCols && filter && filter.length > 0) {
      filter.forEach(filterCol => {
        filteredCols.forEach(tblCol => {
          if (tblCol.name === filterCol.name) {
            if (filterCol.filterList !== undefined) {
              tblCol.options.filterList = filterCol.filterList;
            }
            if (filterCol.display !== undefined) {
              tblCol.options.display = filterCol.display;
            }
          }
        });
      });
    }

    filteredCols.forEach(tblCol => {
      buildSaveFilter.push({
        name: tblCol.name,
        display: tblCol.options.display,
        filterList: tblCol.options.filterList,
      });
    });

    const saveData = {
      buildSaveFilter: buildSaveFilter,
      sortOption: sort,
    };

    setTableSort(sort);
    setSavedFilters(saveData);
    localStorage.setItem(FILTER_TYPE_NAME, JSON.stringify(saveData));
    setCols(filteredCols);
  };
  const selectedAction = action => {
    const findUsers = [];
    const travelerIds = [];
    selectedTravelers.forEach(user => {
      if (action.toString() !== '3') {
        findUsers.push(tableData[user.dataIndex].plan_user_id);
        travelerIds.push(tableData[user.dataIndex].traveler_user_id);
      } else if (
        tableData[user.dataIndex].safe_check_phone_number.length > 1 &&
        tableData[user.dataIndex].safe_check_enrollment_status?.toLowerCase() === 'enrolled'
      ) {
        findUsers.push(tableData[user.dataIndex].plan_user_id);
        travelerIds.push(tableData[user.dataIndex].traveler_user_id);
      }
    });

    setPlanUserIds(findUsers);
    setTravelerIds(travelerIds);
    setSelectDefault({ label: 'Traveler Actions', value: '0' });
    
    const planFormsUsersPlans = [];

    travelerIds.forEach(userID => {
      planFormsUsersPlans.push({
        user_id: userID,
        plan_id: selectedPlan.plan.id,
      });
    });
    
    switch (action.toString()) {
      case '1':
        break;
      case '2':
        setShowSendEmailToTravelers(true);
        break;
      case '3':
        setShowSendSafecheck(true);
        break;
      case '4':
        setShowAddDetails(true);
        break;
      case '5':
        setShowRemoveDetails(true);
        break;
      case '6':
        setShowRemoveTraveler(true);
        break;
      case '7':
        setShowReinstateTraveler(true);
        break;
      case '8':
        setShowMakeGroupLead(true);
        break;
      case '9':
        setShowRemoveGroupLead(true);
        break;
      case '10':
        setPlanFormsUsers(planFormsUsersPlans);
        setShowAddPlanForms(true);
        break;
      case '11':
        setShowBatchDownloadPdfModal(true);
        break;
    }
  };

  const updateTravelerTableCounts = data => {
    setLoadingMsg(data.length < 1 ? 'No travelers found' : loadingMsg);
    const counts = _countBy(data, 'role');

    setTravelerTableCounts({
      travelers: counts['Traveler'] || 0,
      leaders: counts['Group Leader'] || 0,
      total: (counts['Traveler'] || 0) + (counts['Group Leader'] || 0),
    });
  };

  const setMessage = data => {
    setLoadingMsg(data.length < 1 ? 'No travelers found' : loadingMsg);
  };

  const columns = [
    {
      name: 'plan_user_id',
      label: 'Plan User Id',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
        filterList: filterOptions[0].length ? filterOptions[0] : null,
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        filterList: filterOptions[1].length ? filterOptions[1] : null,
      },
    },
    {
      name: 'status',
      label: 'Travel Status',
      options: {
        display: false,
        filterList: filterOptions[2].length ? filterOptions[2] : null,
      },
    },
    {
      name: 'name',
      label: 'Traveler Name',
      options: {
        filter: false,
        filterList: filterOptions[3].length ? filterOptions[3] : null,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        filterList: filterOptions[4].length ? filterOptions[4] : null,
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'safe_check_phone_number',
      label: 'SafeCheck Phone',
      options: {
        filter: false,
      },
    },
    {
      name: 'safe_check_enrollment_status',
      label: 'SafeCheck Status',
      options: {
        filterList: filterOptions[5].length ? filterOptions[5] : null,
      },
    },
    {
      name: 'transportation',
      label: 'Transportation',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1] && transportationFilterChecked) {
              return [`Min Transportation: ${v[0]}`, `Max Transportation: ${v[1]}`];
            } else if (v[0] && v[1] && !transportationFilterChecked) {
              return `Min Transportation: ${v[0]}, Max Transportation: ${v[1]}`;
            } else if (v[0]) {
              return `Min Transportation: ${v[0]}`;
            } else if (v[1]) {
              return `Max Transportation: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(count, filters) {
            if (filters[0] && filters[1]) {
              return count < filters[0] || count > filters[1];
            } else if (filters[0]) {
              return count < filters[0];
            } else if (filters[1]) {
              return count > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Transportation</FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ''}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ''}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%' }}
                />
              </FormGroup>
            </div>
          ),
        },
      },
    },
    {
      name: 'housing',
      label: 'Housing',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1] && housingFilterChecked) {
              return [`Min Housing: ${v[0]}`, `Max Housing: ${v[1]}`];
            } else if (v[0] && v[1] && !housingFilterChecked) {
              return `Min Housing: ${v[0]}, Max Housing: ${v[1]}`;
            } else if (v[0]) {
              return `Min Housing: ${v[0]}`;
            } else if (v[1]) {
              return `Max Housing: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(count, filters) {
            if (filters[0] && filters[1]) {
              return count < filters[0] || count > filters[1];
            } else if (filters[0]) {
              return count < filters[0];
            } else if (filters[1]) {
              return count > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Housing</FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ''}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ''}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%' }}
                />
              </FormGroup>
            </div>
          ),
        },
      },
    },
    {
      name: 'activity',
      label: 'Activity',
      options: {
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1] && activityFilterChecked) {
              return [`Min Activity: ${v[0]}`, `Max Activity: ${v[1]}`];
            } else if (v[0] && v[1] && !activityFilterChecked) {
              return `Min Activity: ${v[0]}, Max Activity: ${v[1]}`;
            } else if (v[0]) {
              return `Min Activity: ${v[0]}`;
            } else if (v[1]) {
              return `Max Activity: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          logic(count, filters) {
            if (filters[0] && filters[1]) {
              return count < filters[0] || count > filters[1];
            } else if (filters[0]) {
              return count < filters[0];
            } else if (filters[1]) {
              return count > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Activity</FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterList[index][0] || ''}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                  label="max"
                  value={filterList[index][1] || ''}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%' }}
                />
              </FormGroup>
            </div>
          ),
        },
      },
    },

    {
      name: 'number_of_forms',
      label: '# of Forms',
      options: {
        filter: false,
        download: true,
        display: true,
        //filterList: filterOptions[9].length ? filterOptions[9] : null,
      },
    },

    {
      name: 'plan_forms',
      label: 'Plan Forms',
      options: {
        filter: false,
        download: true,
        display: false,
        //filterList: filterOptions[9].length ? filterOptions[9] : null,
      },
    },
    {
      name: 'number_of_accepted_forms',
      label: '# Forms Accepted',
      options: {
        filter: false,
        download: true,
        display: true,
        //filterList: filterOptions[9].length ? filterOptions[9] : null,
      },
    },
    {
      name: 'accepted_forms',
      label: 'Accepted Forms',
      options: {
        filter: false,
        download: true,
        display: false,
        //filterList: filterOptions[9].length ? filterOptions[9] : null,
      },
    },
    {
      name: 'number_of_submitted_forms',
      label: '# Forms Submitted',
      options: {
        filter: false,
        download: true,
        display: true,
        //filterList: filterOptions[9].length ? filterOptions[9] : null,
      },
    },
    {
      name: 'submitted_forms',
      label: 'Submitted Forms',
      options: {
        filter: false,
        download: true,
        display: false,
        //filterList: filterOptions[9].length ? filterOptions[9] : null,
      },
    },
    {
      name: 'number_of_started_forms',
      label: '# Forms Started',
      options: {
        filter: false,
        download: true,
        display: true,
        //filterList: filterOptions[9].length ? filterOptions[9] : null,
      },
    },
    {
      name: 'started_forms',
      label: 'Started Forms',
      options: {
        filter: false,
        download: true,
        display: false,
        //filterList: filterOptions[9].length ? filterOptions[9] : null,
      },
    },
    {
      name: 'number_of_not_started_forms',
      label: '# Forms Not Started',
      options: {
        filter: false,
        download: true,
        display: true,
        //filterList: filterOptions[9].length ? filterOptions[9] : null,
      },
    },
    {
      name: 'not_started_forms',
      label: 'Not Started Forms',
      options: {
        filter: false,
        download: true,
        display: false,
        //filterList: filterOptions[9].length ? filterOptions[9] : null,
      },
    },

    {
      name: 'plan_name',
      label: 'Plan Name',
      options: {
        filter: false,
        download: true,
        display: false,
        filterList: filterOptions[9].length ? filterOptions[9] : null,
      },
    },
    {
      name: 'plan_type_name',
      label: 'Plan Type',
      options: {
        filter: false,
        download: true,
        display: false,
        filterList: filterOptions[10].length ? filterOptions[10] : null,
      },
    },
    {
      name: 'plan_start',
      label: 'Plan Start',
      options: {
        filter: false,
        download: true,
        display: false,
        filterList: [],
      },
    },
    {
      name: 'plan_end',
      label: 'Plan End',
      options: {
        filter: false,
        download: true,
        display: false,
        filterList: [],
      },
    },
    {
      name: 'locations',
      label: 'Plan Locations',
      options: {
        filter: false,
        download: true,
        display: false,
        filterList: filterOptions[13].length ? filterOptions[13] : null,
      },
    },
    {
      name: 'avatar',
      label: 'Avatar',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
        filterList: filterOptions[14].length ? filterOptions[14] : null,
      },
    },
    {
      name: 'traveler_user_id',
      label: 'Traveler User Id',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
        filterList: filterOptions[15].length ? filterOptions[15] : null,
      },
    },
    {
      name: 'student_id',
      label: 'Student ID',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'preferred_first_name',
      label: 'Preferred First Name',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'traveler_type',
      label: 'Traveler Type',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'date_of_birth',
      label: 'Date of Birth',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'emergency_contact_name',
      label: 'Emergency Contact Name',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'emergency_contact_relationship',
      label: 'Emergency Contact Relationship',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'emergency_contact_phone_number',
      label: 'Emergency Contact Phone',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'emergency_contact_email',
      label: 'Emergency Contact Email',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'emergency_contact_address',
      label: 'Emergency Contact Address',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'secondary_emergency_contact_name',
      label: 'Emergency Contact 2 Name',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'secondary_emergency_contact_relationship',
      label: 'Emergency Contact 2 Relationship',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'secondary_emergency_contact_phone_number',
      label: 'Emergency Contact 2 Phone',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'secondary_emergency_contact_email',
      label: 'Emergency Contact 2 Email',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'secondary_emergency_contact_address',
      label: 'Emergency Contact 2 Address',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'countries_of_citizenship',
      label: 'Country of Citizenship',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'passport_number',
      label: 'Passport Number',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'passport_issuer',
      label: 'Passport Issuer',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'passport_status',
      label: 'Passport Status',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'place_of_birth',
      label: 'Place of Birth',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'residency_status',
      label: 'Residency Status',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'visa_status',
      label: 'Visa Status',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'sex',
      label: 'Sex Assigned at Birth',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'gender_identity',
      label: 'Gender',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'pronoun',
      label: 'Pronouns',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'race',
      label: 'Race',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'ethnicity',
      label: 'Ethnicity',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'disability',
      label: 'Disability',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
    {
      name: 'custom_fields',
      label: 'Custom Fields',
      options: {
        display: false,
        filter: false,
        filterList: [],
      },
    },
  ];

  const options = {
    customSearchRender: debounceSearchRender(DEBOUNCE_VALUE),
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: false,
    filterType: 'multiselect',
    rowsPerPage: 50,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    downloadOptions: {
      filename: 'plan_traveler_list.csv',
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    disableToolbarSelect: true,
    searchOpen: true,
    searchPlaceholder: 'Enter keyword to search travelers...',
    print: false,
    textLabels: {
      body: {
        noMatch: loadingMsg,
      },
    },
    onFilterChange: (columnChanged, filterList) => {
      setFilterOptions(filterList);
    },
    onTableChange: (action, tableState) => {
      const keyUpdates = ['filterChange', 'search', 'resetFilters', 'viewColumnsChange', 'sort'];
      if (keyUpdates.includes(action)) {
        const saveState = {
          columns: tableState.columns,
          filters: tableState.filterList,
          search: tableState.searchText,
          sortOrder: tableState.sortOrder,
        };

        saveState.columns.forEach((col, index) => {
          col.filterList = saveState.filters[index];
        });

        const filteredList = [];
        _map(tableState.displayData, item => {
          filteredList.push(tableData[item.dataIndex]);
        });

        updateTravelerTableCounts(filteredList);
        applyFilterToTableData(saveState.columns, saveState.sortOrder);
      }

      if (!loading) {
        setMessage(tableState.displayData);
      }
    },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      setSelectedTravelers(allRowsSelected);
    },
  };

  return (currentUser['client_user_role?'] && permissions.travel_plans_settings?.includes('view_travelers')) ||
    currentUser['is_traveler?'] ? (
    <div className={sOvTravelers['ov-travelers']} style={{ width:props.as === 'leader' ? "64%" : "100%" }}>
      {!!selectedPlan && !!selectedPlan.plan && (
        <>
          {props.as !== 'traveler' && selectedPlan.plan.attributes.group && (
            <div className={sOvTravelers['traveler-type-header']}>
              <div className={sOvTravelers['traveler-view-type-options']}>
                <div
                  onClick={() => {
                    setViewStyle('table');
                  }}
                >
                  <ViaIcon name="table" color={viewStyle === 'table' ? 'navigation' : 'darkText'} size="large" />
                </div>
                <div
                  onClick={() => {
                    setViewStyle('roster');
                  }}
                >
                  <ViaIcon name="roster" color={viewStyle === 'roster' ? 'navigation' : 'darkText'} size="large" />
                </div>
              </div>
              {props.as === 'admin' && !selectedPlan.plan.attributes.removed ?
                  <div className={sOvTravelers['traveler-view-type-action']}>
                    <Button display="primary" kind="solid" onClick={() => setShowAddTraveler(true)}>
                      <ViaIcon name="plus" color="white" size="xsmall" ariaLabel="Create new group plan" />
                      &nbsp; Add Travelers To Plan
                    </Button>
                  </div>
                : null
              }

              {viewStyle === 'table' && props.as === 'admin' && (
                <div className={sOvTravelers['ov-travelers-action-select']}>
                  <TravelerActionsSelect
                    value={selectDefault}
                    options={selectOptions}
                    isDisabled={!selectedTravelers || selectedTravelers.length < 1}
                    onChange={e => {
                      selectedAction(e.value);
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div className={sOvTravelers['ov-travelers-headline']}>
            <Headline
              style={{
                fontFamily: 'IntroBoldItalic, sans-serif',
                fontSize: '32px',
                lineHeight: '40px',
              }}
              tag="div"
              as="div"
            >
              Who's Going
            </Headline>
            <div className={sOvTravelers['ov-travelers-headline-counts']}>
              {viewStyle === 'roster' ? (
                <Paragraph italic>
                  {travelerCounts.total} Total, {travelerCounts.travelers} Travelers, {travelerCounts.leaders} Group
                  Leaders
                </Paragraph>
              ) : (
                <Paragraph italic>
                  {travelerTableCounts.total} Total, {travelerTableCounts.travelers} Travelers,{' '}
                  {travelerTableCounts.leaders} Group Leaders
                </Paragraph>
              )}
            </div>
          </div>
          <div className={sOvTravelers['ov-travelers-traveler-wrapper']}>
            {!selectedPlan.travelers ||
            selectedPlan.travelers.length === 0 ||
            (viewStyle === 'roster' && (!goingList || goingList.length === 0)) ? (
              <div>
                <div className={sOvTravelers['background-image']}>
                  <img src={CityScape} alt="city background image" longdesc="city background image" />
                </div>
                <div className={sOvTravelers['no-travelers-container']}>
                  <Paragraph bold size="large">
                    There are no Travelers added to this Group Plan... yet!
                  </Paragraph>
                  <Paragraph size="large">
                    Add Travelers to this Plan so you can communicate with them, designate Group <br />
                    Leaders, add travel details to Traveler Itineraries, and more.
                  </Paragraph>
                </div>
              </div>
            ) : null}

            {viewStyle === 'roster' &&
              !!goingList &&
              goingList.map(traveler => (
                <div className={sOvTravelers['ov-travelers-traveler']} key={traveler.plan_user_id}>
                  <div className={sOvTravelers['ov-travelers-traveler-image']}>
                    <img
                      alt={
                        userId.toString() === traveler.traveler_user_id.toString() ? 'User avatar' : 'Traveler avatar'
                      }
                      longdesc={
                        userId.toString() === traveler.traveler_user_id.toString() ? 'User avatar' : 'Traveler avatar'
                      }
                      src={traveler.avatar}
                    />
                  </div>
                  <div className={sOvTravelers['ov-travelers-traveler-info']}>
                    <Headline
                      style={{
                        fontFamily: 'IntroBoldItalic, sans-serif',
                        fontSize: '24px',
                        lineHeight: '32px',
                      }}
                      tag="div"
                      as="div"
                    >
                      {userId.toString() === traveler.traveler_user_id.toString() ? (
                        "You're going"
                      ) : (
                        <>{traveler.name.length > 1 ? traveler.name :  props.as !== 'traveler' ?  traveler.email : "--"}</>
                      )}
                    </Headline>
                    {traveler.role.toLowerCase() !== 'traveler' && <Paragraph>Group Leader</Paragraph>}
                  </div>
                  {props.as !== 'traveler' && (
                    <>
                      <div className={sOvTravelers['ov-travelers-traveler-counts']}>
                        <Paragraph>
                          {traveler.transportation} Transportation <br />
                          {traveler.activity} Activity <br />
                          {traveler.housing} Housing <br />
                        </Paragraph>
                      </div>
                      <div className={sOvTravelers['ov-travelers-traveler-link']}>
                        {props.as === 'admin' && (
                          <Paragraph>
                            <span
                              className={sOvTravelers['ov-travelers-traveler-link-content']}
                              onClick={() =>
                                props.history.push(
                                  `/plans/traveler-itinerary/${selectedPlan.plan.id}/${traveler.plan_user_id}`,
                                  {
                                    backTo: `admin-plan-overview/${selectedPlan.plan.id}`,
                                  },
                                )
                              }
                            >
                              View Itinerary
                            </span>
                          </Paragraph>
                        )}
                        {props.as === 'leader' && (
                          <Paragraph>
                            <span
                              className={sOvTravelers['ov-travelers-traveler-link-content']}
                              onClick={() =>
                                props.history.push(
                                  `/plans/leader-traveler-itinerary/${selectedPlan.plan.id}/${traveler.plan_user_id}`,
                                  {
                                    backTo: `plan-overview/${selectedPlan.plan.id}`,
                                  },
                                )
                              }
                            >
                              View Itinerary
                            </span>
                          </Paragraph>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ))}
            {viewStyle === 'table' && !!tableData && tableData.length > 0 && (
              <>
                <div className={sOvTravelers['ov-travelers-table']}>
                  <MuiThemeProvider theme={viaMuiTheme(true)}>
                    <MUIDataTable title="" data={tableData} columns={cols} options={options} responsive="scrollFullHeight" />
                  </MuiThemeProvider>
                </div>
                {/* <div className={sOvTravelers['add-travelers-link-wrapper']}>
                  {!selectedPlan.plan.attributes.removed && props.as === 'admin' ? (
                    <Paragraph size="large">
                      <span onClick={() => setShowAddTraveler(true)} className={sOvTravelers['add-link']}>
                        Add Travelers to This Plan
                      </span>
                    </Paragraph>
                  ) : null}
                </div> */}
              </>
            )}
          </div>
          <ViaModal
            showing={showAddTraveler}
            onClose={() => setShowAddTraveler(false)}
            headerText="Add Travelers to Plan"
          >
            <BatchAddTravelerToPlan planId={selectedPlan.plan.id} onClose={() => setShowAddTraveler(false)} />
          </ViaModal>
          <AdminDetailToTraveler
            show={showAddDetails}
            onClose={() => {
              setShowAddDetails(false);
            }}
            planId={selectedPlan.plan.id}
            planUsers={planUserIds}
          />
          <AdminDetailFromTraveler
            show={showRemoveDetails}
            onClose={() => {
              setShowRemoveDetails(false);
            }}
            planId={selectedPlan.plan.id}
            planUsers={planUserIds}
          />
          <MakeGroupLead
            show={showMakeGroupLead}
            onClose={() => {
              setShowMakeGroupLead(false);
            }}
            planId={selectedPlan.plan.id}
            planUsers={planUserIds}
          />
          <RemoveGroupLead
            show={showRemoveGroupLead}
            onClose={() => {
              setShowRemoveGroupLead(false);
            }}
            planId={selectedPlan.plan.id}
            planUsers={planUserIds}
          />
          <RemoveTraveler
            show={showRemoveTraveler}
            onClose={() => {
              setShowRemoveTraveler(false);
            }}
            planId={selectedPlan.plan.id}
            planUsers={planUserIds}
          />
          <ReinstateTraveler
            show={showReinstateTraveler}
            onClose={() => {
              setShowReinstateTraveler(false);
            }}
            planId={selectedPlan.plan.id}
            planUsers={planUserIds}
          />
          <SendSafecheck
            show={showSendSafecheck}
            onClose={() => {
              setShowSendSafecheck(false);
            }}
            planUsers={planUserIds}
            as={props.as}
            planId={selectedPlan.plan.id}
          />

          <BatchSendTravelersMessage
            show={showSendEmailToTravelers}
            onClose={() => {
              setShowSendEmailToTravelers(false);
            }}
            selectedUsers={_uniq(travelerIds)}
            userIds={_uniq(travelerIds)}
            customAliases={customAliases}
            setSelectedPrograms={() => {
              setTravelerIds();
            }}
          />
          {showAddPlanForms && (
            <AddPlanFormsToTravelersModal
              handleClose={() => setShowAddPlanForms(false)}
              show={showAddPlanForms}
              action="travelerGrid"
              handleAddForm={()=>{
                dispatch(getPlan(selectedPlan.plan.id));
              }}
              userAndPlanIds={planFormsUsers}
            />
          )}
          {showBatchDownloadPdfModal && (
            <BatchDownloadPdfModal
              submissionIds={travelerIds}
              action="traveler"
              planIds= {[selectedPlan.plan.id]}
              show={showBatchDownloadPdfModal}
              type="Plan Form"
              handleClose={() => {
                setShowBatchDownloadPdfModal(false);
              }}
            />
          )}
        </>
      )}
      {showToaster ? (
        <ToastMessage
          message={toasterMessage}
          isSuccess={false}
          show={showToaster}
          onClose={() => {
            setShowToaster(false);
            setToasterMessage('');
          }}
        />
      ) : null}
    </div>
  ) : (
    <AccessDenied />
  );
}

OvTravelers.propTypes = {
  as: propTypes.oneOf(['traveler', 'admin', 'leader']).isRequired,
};

export default enhance(OvTravelers);
