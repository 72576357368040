import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import StyledIcon from '../styles/Icon.styled';
import Tooltip from './Tooltip.styled';

const Container = styled.span`
  display: flex;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: text-top;
  margin-top: 5px;
`;

const Icon = styled.svg`
  fill: none;
  padding-bottom: 11px;
  stroke: white;
  stroke-width: 2px;
`;

const InputLabel = styled.label`
  color: ${({ theme }) => theme.editTextArea.label.fontColor};
  font-family: ${({ theme }) => theme.editTextArea.label.fontFamily};
  font-size: ${({ theme }) => theme.editTextArea.label.fontSize};
`;

const Label = styled.label`
  color: ${({ theme }) => theme.checkbox.fontColor};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;

  ${props =>
    props.openSans
      ? `
  font-family: ${({ theme }) => theme.checkboxOpenSans.fontFamily};
  font-size: ${({ theme }) => theme.checkboxOpenSans.fontSize};
  line-height: ${({ theme }) => theme.checkboxOpenSans.lineHeight};

  `
      : `font-family: ${({ theme }) => theme.checkbox.fontFamily};
  font-size: ${({ theme }) => theme.checkbox.fontSize};
  line-height: ${({ theme }) => theme.checkbox.lineHeight};`};
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.4;
  `};
`;

const LabelText = styled.span`
  display: inline-block;
  padding-left: 10px;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:disabled {
    cursor: not-allowed;
  }
`;

const Required = styled.span`
  color: ${({ theme }) => theme.defaults.errorColor};
  padding-left: 5px;
`;

const CheckboxText = styled.div`
  display: inline-block;
  padding-top: 0.2em;

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.4;
  `};
`;

const StyledCheckbox = styled.div`
  background-color: #fff;
  //border: 1px solid ${({ theme }) => theme.checkbox.borderColor};
  border: 1px solid #446372;
  ${props => props.type !== 'accent'} {
    border: 1px solid var(--theme_color_accent, ${({ theme }) => theme.checkbox.borderColor});
  }
  ${props => !props.checked} {
    //background-color: ${({ theme }) => theme.checkbox.checkedColor};
    background-color: #446372;
    ${props => props.type !== 'accent'} {
      background-color: var(--theme_color_accent, ${({ theme }) => theme.checkbox.checkedColor});
    }
  }
  border-radius: 2px;
  display: inline-block;
  height: 16px;
  transition: all 150ms;
  width: 16px;

  ${HiddenCheckbox}:focus + & {
    // border: ${({ theme }) => theme.checkbox.focus.focusBorder};
    border: 2px solid; 
    outline-color: ${({ theme }) => theme.checkbox.focus.outlineColor};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

const TooltipContainer = styled.span`
  padding-left: 5px;
`;

const Checkbox = forwardRef(
  (
    {
      children,
      defaultChecked,
      disabled,
      id,
      label,
      name,
      onChange,
      handleOnClick,
      tooltip,
      required,
      type,
      value,
      resetValue = false,
      openSans = false,
      isRiskAlert=false,
      ...rest
    },
    forwardedRef,
  ) => {
    const [checked, setChecked] = useState(defaultChecked || value || false);
    
    useEffect(() => {
      if (onChange) {
        onChange(checked);
      }
    }, [checked]);
    
    useEffect(() => {
      setChecked(defaultChecked || value || false);
    }, [resetValue]);

    useEffect(()=>{
      setChecked(defaultChecked);
    },[])

    return (
      <>
        <Label disabled={disabled} htmlFor={id}>
          <CheckboxContainer>
            <HiddenCheckbox
              title="Agree terms of use"
              disabled={disabled}
              defaultChecked={checked}
              id={id}
              name={name}
              onChange={e => {               
                  if (!disabled) {
                    if(isRiskAlert){
                      setChecked(!checked);
                    }
                    else {
                      setChecked(e.target.checked);
                    }
                  }
                } 
              }
              ref={forwardedRef}
              value={value}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  if (!disabled) {
                    setChecked(!checked);
                  }
                }
              }}
              onClick={handleOnClick}
            />
            <StyledCheckbox checked={checked} disabled={disabled} type={type}>
              <Icon viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
              </Icon>
            </StyledCheckbox>
          </CheckboxContainer>
          <InputLabel htmlFor={id}>
            <LabelText openSans={openSans}>{label}</LabelText>

            {required && <Required>*</Required>}
            {tooltip && (
              <TooltipContainer>
                <Tooltip toolTipText={tooltip}>
                  <StyledIcon type="InformationCircle" size="14px" />
                </Tooltip>
              </TooltipContainer>
            )}
            {children && <CheckboxText>{children}</CheckboxText>}
          </InputLabel>
        </Label>
      </>
    );
  },
);
Checkbox.displayName = 'Checkbox';
export default Checkbox;
