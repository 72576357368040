import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ContentBlock from '../../structure/ContentBlock';
import {
  TextBlockTag,
  TextBlockRichContentSection,
  TextBlockRichContentSectionContainer,
} from '../../structure/ContentBlock.styled';

import { TabCard, TabHeading, TabCardPanel, TabContentContainer } from '../TabContent.styled';

import isEmpty from '../../../utils/isEmpty';
import { breakpoints } from '../../../utils/breakpoints';
import EmbeddedVideoView from '../../../../../components/EmbeddedVideoView';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media ${breakpoints.tablet} {
    gap: 1.5rem;
  }
`;

export default function AboutProgram() {
  const programAlias = useSelector(state => state.auth.organization.info.alias_program);

  const {
    types,
    about_program_video_url,
    language: { primary: primaryLanguage, isImmersive: isImmersive },
    housing: { types: housingTypes, notes: housingNotes },
    location: { notes: locationNotes, locations },
    hostOrganization: { name: hostOrganizationName, notes: hostOrganizationNotes },
    activity: { activities, excursions, addons, notes: activityNotes },
  } = useSelector(state => state.programBrochure);

  return (
    <TabContentContainer>
      <TabHeading level={2}>About {programAlias}</TabHeading>
      <TabCard>
        <TabCardPanel className="full" tabIndex={0}>
          <ContentContainer>
            {!isEmpty(types) && (
              <ContentBlock title={`${programAlias} Type`}>
                <TextBlockRichContentSectionContainer>
                  <p>{types.join(', ')}</p>
                </TextBlockRichContentSectionContainer>
              </ContentBlock>
            )}

            {(!isEmpty(primaryLanguage) || !isEmpty(locations) || !isEmpty(locationNotes)) && (
              <ContentBlock title="Language &amp; Location">
                {isImmersive && <TextBlockTag className="text-box__tag">Language Immersion</TextBlockTag>}
                {!isEmpty(primaryLanguage) && (
                  <TextBlockRichContentSection
                    headingText="Instructional Languages"
                    headingWeight="medium"
                    headingColor="#446372"
                    plainParagraph
                    sectionContent={primaryLanguage}
                  />
                )}
                {!isEmpty(locations) && (
                  <TextBlockRichContentSection
                    headingText="Location"
                    headingWeight="medium"
                    headingColor="#446372"
                    plainParagraph
                    sectionContent={locations.map(location => location.locality).join('; ')}
                  />
                )}
                {!isEmpty(locationNotes) && (
                  <TextBlockRichContentSection
                    headingText="Language and Location Information"
                    headingWeight="medium"
                    sectionContent={locationNotes}
                  />
                )}
              </ContentBlock>
            )}

            {(housingTypes.some(type => type.included) || !isEmpty(housingNotes)) && (
              <ContentBlock title="Housing">
                {housingTypes.some(type => type.included) && (
                  <TextBlockRichContentSection
                    headingText="Housing Type"
                    headingWeight="medium"
                    headingColor="#446372"
                    plainParagraph
                    sectionContent={housingTypes
                      .filter(type => type.included)
                      .map(type => type.housing_type.name)
                      .join(', ')}
                  />
                )}
                {!isEmpty(housingNotes) && (
                  <TextBlockRichContentSection
                    headingText="Housing Information"
                    headingWeight="medium"
                    sectionContent={housingNotes}
                  />
                )}
              </ContentBlock>
            )}

            {(!isEmpty(hostOrganizationName) || !isEmpty(hostOrganizationNotes)) && (
              <ContentBlock title="Host Organization">
                {!isEmpty(hostOrganizationName) && (
                  <TextBlockRichContentSection
                    headingText="Host Organization"
                    headingWeight="medium"
                    headingColor="#446372"
                    plainParagraph
                    sectionContent={hostOrganizationName}
                  />
                )}
                {!isEmpty(hostOrganizationNotes) && (
                  <TextBlockRichContentSection
                    headingText="Host Organization Information"
                    headingWeight="medium"
                    sectionContent={hostOrganizationNotes}
                  />
                )}
              </ContentBlock>
            )}

            {(!isEmpty(activities) || !isEmpty(excursions) || !isEmpty(addons) || !isEmpty(activityNotes)) && (
              <ContentBlock title="Activities">
                {!isEmpty(activities) && (
                  <TextBlockRichContentSection
                    headingText="Activities"
                    headingWeight="medium"
                    sectionContent={activities}
                  />
                )}
                {!isEmpty(excursions) && (
                  <TextBlockRichContentSection
                    headingText="Excursions"
                    headingWeight="medium"
                    sectionContent={excursions}
                  />
                )}
                {!isEmpty(addons) && (
                  <TextBlockRichContentSection headingText="Add-ons" headingWeight="medium" sectionContent={addons} />
                )}
                {!isEmpty(activityNotes) && (
                  <TextBlockRichContentSection
                    headingText="Activities Information"
                    headingWeight="medium"
                    sectionContent={activityNotes}
                  />
                )}
              </ContentBlock>
            )}

            {about_program_video_url && <EmbeddedVideoView url={about_program_video_url} />}
          </ContentContainer>
        </TabCardPanel>
      </TabCard>
    </TabContentContainer>
  );
}
