import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './index.module.css';
import deleteIcon from './deleteIcon.png';
import closeIcon from './closeIcon.png';
import ProfileTextInput from '../../../../../travelerProfile/components/base/inputs/ProfileTextInput';
import { deletePlan } from '../../../../../../actions/plans';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import toastMessageStyles from '../../../../../viaGlobal/components/toastMessage/toastMessage.scss';


function DeletePlanDialog({ show, handleClose, planId, planName }) {
  const [text, setText] = useState('');
  const dispatch = useDispatch();
  const planDelete = useSelector(state => state?.plans?.planDelete);

  const handleSubmit = () => {
    dispatch(
      deletePlan(planId, (status, message) => {
        if (status) {
          toast(message, {
            type: 'success',
            className: toastMessageStyles['successContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['successBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });

          window.location.href = "/plans/plan-list-beta"; 
          handleClose(); 

        } else {
          toast(message, {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
        }
      }),
    );
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header className={s.modalHeader}>
        <div className={s.title}>
          <h1 className={s.heading}>Confirm Deletion</h1>
          <a
            href="#"
            role="button"
            onClick={e => {
              e.preventDefault();
              handleClose();
            }}
          >
            <img src={closeIcon} alt="Close" />
          </a>
        </div>
      </Modal.Header>
      <Modal.Body className={s.modalBody}>

        <div className={s.container}>
          <div className={s.imageContainer}>
            <img src={deleteIcon} alt="Alert" className={s.image} />
          </div>

          <div className={s.textContainer}>
            <div>You are about to permanently delete this travel plan and all of the associated data, including:</div>

            <ul>
              <li>Traveler / Group Leader List</li>
              <li>All Group and Solo Itinerary Items</li>
              <li>Traveler Plan Forms</li>
            </ul>

            <div>
              <strong>This action cannot be undone.</strong> All plan data will be lost and cannot be recovered. Travelers will not be notified.
            </div>
          </div>
        </div>

        <div className={s.footer}>
          <div className={s.inputContainer}>
            <ProfileTextInput
              label={`Type DELETE to delete ${planName}`}
              placeholder={'Type DELETE to confirm'}
              handleOnChange={e => {
                setText(e.target.value);
              }}
              value={text}
            />
          </div>

          <div className={s.btnContainer}>
            <button type="button" className={s.closeButton} onClick={handleClose}>
              Close
            </button>
            <button
              type="button"
              disabled={text !== 'DELETE' || planDelete?.loading}
              className={`btn btn-primary ${s.deleteBtn}`}
              onClick={handleSubmit}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default withStyles(s, toastMessageStyles)(DeletePlanDialog);
